
.u-shake {
	animation: shake 2s infinite;
}


//ボタン用
@keyframes shake {
	50% {
		transform: scale(1, 1);
	}
	52% {
			transform: scale(0.98, 0.95)
	}
	54% {
			transform: scale(1, 1);
	}
	56% {
			transform: scale(0.98, 0.95)
	}
	58% {
			transform: scale(1, 1);
	}
	60% {
			transform: scale(0.98, 0.95)
	}
}