@use "../global" as g;

.pos {
	&Rel {
		position: relative;
	}
	&Abs {
		position: absolute;
	}
	&Fixed {
		position: fixed;
	}
	&Sticky {
		position: sticky;
	}
}

@each $key, $value in g.$breakpoints {
	@media #{ $value } {
		.pos {
			&Rel-#{$key} {
				position: relative;
			}
			&Abs-#{$key} {
				position: absolute;
			}
			&Fixed-#{$key} {
				position: fixed;
			}
			&Sticky-#{$key} {
				position: sticky;
			}
		}
	}
	
}