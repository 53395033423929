@use "../global" as g;

@include g.outputUtilityClass( g.$u-fontSize, 'fs', 'font-size');
@include g.outputUtilityClass( g.$u-fontWeight, 'fw', 'font-weight');
@include g.outputUtilityClass( g.$u-lineHeight, 'lh', 'line-height');
@include g.outputUtilityClass( g.$u-letterSpace, 'ls', 'letter-spacing');

.u-wbr {
	word-break: keep-all;
	overflow-wrap: break-word;
}

.align {
	&Center {
		text-align: center;
	}
	&Left {
		text-align: left;
	}
	&Right {
		text-align: right;
	}
	&Justify {
		text-align: justify
	}
}

@each $key, $value in g.$breakpoints {
	@media #{ $value } {
		.align {
			&Center-#{$key} {
				text-align: center;
			}
			&Left-#{$key} {
				text-align: left;
			}
			&Right-#{$key} {
				text-align: right;
			}
			&Justify-#{$key} {
				text-align: justify
			}
		}
	}
}