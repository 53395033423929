@charset "UTF-8";
.animated[data-animated=true] {
  visibility: visible;
}
.animated[data-animated=true].fadeIn {
  animation-name: fadeIn;
}
.animated[data-animated=true].fadeInDown {
  animation-name: fadeInDown;
}
.animated[data-animated=true].fadeInLeft {
  animation-name: fadeInLeft;
}
.animated[data-animated=true].fadeInRight {
  animation-name: fadeInRight;
}
.animated[data-animated=true].fadeInUp {
  animation-name: fadeInUp;
}
.animated[data-animated=true].clipLeft {
  animation-name: clipLeft;
}
.animated[data-animated=true].clipDown {
  animation-name: clipDown;
}
.animated[data-animated=true].standUp {
  animation-timing-function: ease-out;
  transform-origin: bottom;
  animation-name: standUp;
}
.animated[data-animated=true].hangDown {
  animation-timing-function: ease-out;
  transform-origin: top;
  animation-name: standUp;
}
.animated[data-animated=true].zoomIn {
  animation-name: zoomIn;
}
.animated[data-animated=true].stamp {
  animation-name: stamp;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@keyframes fadeInDown {
  from {
    opacity: 0;
    transform: translate3d(0, -20%, 0);
  }
  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}
@keyframes fadeInLeft {
  from {
    opacity: 0;
    transform: translate3d(-20%, 0, 0);
  }
  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}
@keyframes fadeInRight {
  from {
    opacity: 0;
    transform: translate3d(20%, 0, 0);
  }
  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}
@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translate3d(0, 20%, 0);
  }
  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}
@keyframes zoomIn {
  from {
    opacity: 0;
    transform: scale3d(0 0.3, 0 0.3, 0 0.3);
  }
  50% {
    opacity: 1;
  }
}
@keyframes clipLeft {
  from {
    clip-path: inset(0 100% 0 0);
  }
  to {
    clip-path: inset(0);
  }
}
@keyframes clipDown {
  from {
    clip-path: inset(0 0 100% 0);
  }
  to {
    clip-path: inset(0);
  }
}
@keyframes standUp {
  0% {
    transform: rotateX(90deg);
  }
  100% {
    transform: rotateX(0);
  }
}
@keyframes hangDown {
  0% {
    transform: rotateX(90deg);
  }
  100% {
    transform: rotateX(0);
  }
}
@keyframes stamp {
  0% {
    opacity: 0;
    transform: rotate(-30deg) scale(2.5);
  }
  50% {
    opacity: 0.8;
    transform: rotate(0deg) scale(1.04);
  }
  100% {
    opacity: 1;
    transform: rotate(0deg) scale(1.04);
  }
}
.animated {
  animation-duration: 1s;
  animation-fill-mode: both;
  visibility: hidden;
}
.animated.infinite {
  animation-iteration-count: infinite;
}
.animated.delay-250ms {
  animation-delay: 250ms;
}
.animated.delay-500ms {
  animation-delay: 500ms;
}
.animated.delay-750ms {
  animation-delay: 750ms;
}
.animated.delay-1s {
  animation-delay: 1s;
}
.animated.delay-1-5s {
  animation-delay: 1500ms;
}
.animated.delay-2s {
  animation-delay: 2s;
}
.animated.delay-2-5s {
  animation-delay: 2500ms;
}
.animated.delay-3s {
  animation-delay: 3s;
}
.animated.delay-4s {
  animation-delay: 4s;
}
.animated.delay-5s {
  animation-delay: 5s;
}
.animated.fast {
  animation-duration: 800ms;
}
.animated.faster {
  animation-duration: 500ms;
}
.animated.slow {
  animation-duration: 2s;
}
.animated.slower {
  animation-duration: 3s;
}

@font-face {
  font-family: "icomoon";
  src: url("../../fonts/icomoon.eot?6jck9b");
  src: url("../../fonts/icomoon.eot?6jck9b#iefix") format("embedded-opentype"), url("../../fonts/icomoon.ttf?6jck9b") format("truetype"), url("../../fonts/icomoon.woff?6jck9b") format("woff"), url("../../fonts/icomoon.svg?6jck9b#icomoon") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: block;
}
[class^=icon-], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: "icomoon" !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-phone:before {
  content: "\e942";
}

.icon-envelop:before {
  content: "\e945";
}

:root {
  --Main: #e26a12;
  --White: #fff;
  --Black: #222222;
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}

::before,
::after {
  box-sizing: inherit;
  text-decoration: inherit;
  vertical-align: inherit;
}

b, strong {
  font-weight: bold;
}

main {
  display: block;
}

ul {
  list-style: none;
}

blockquote, q {
  quotes: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

td, th {
  word-break: break-all;
}

img {
  max-width: 100%;
  height: auto;
  border-style: none;
}

video {
  outline: none;
}

pre, code {
  -moz-tab-size: 4;
  tab-size: 4;
}

svg:not([fill]) {
  fill: currentColor;
}

[hidden] {
  display: none;
  /* Add the correct display in IE */
}

/* 
 * Form
 */
button,
input,
optgroup,
select,
textarea {
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}

/* Show the overflow in IE. */
button,
input {
  /* Show the overflow in Edge. */
  overflow: visible;
}

/* Remove the inheritance of text transform in Edge, Firefox, and IE.  */
button,
select {
  /* Remove the inheritance of text transform in Firefox. */
  text-transform: none;
}

/* button */
button,
[type=button],
[type=reset],
[type=submit] {
  -webkit-appearance: button;
  display: inline-block;
  cursor: pointer;
}

[role=button] {
  cursor: pointer;
}

/* normalize 通り */
fieldset {
  padding: 0.35em 0.75em 0.625em;
}

/* normalize 通り */
legend {
  box-sizing: border-box;
  color: inherit;
  display: table;
  max-width: 100%;
  padding: 0;
  white-space: normal;
}

input,
textarea,
select,
button {
  padding: 0.25em 0.5em;
  color: #333;
  border: 1px solid var(--Black);
  border-radius: 0;
}

[type=color] {
  padding: 0;
}

[type=range] {
  vertical-align: middle;
}

/* 
 * Accessibility
 */
/* Specify the progress cursor of updating elements */
[aria-busy=true] {
  cursor: progress;
}

/* Specify the pointer cursor of trigger elements */
[aria-controls] {
  cursor: pointer;
}

/* Specify the unstyled cursor of disabled, not-editable, or otherwise inoperable elements */
[aria-disabled] {
  cursor: default;
}

/* Hide content from screens but not screenreaders */
@media screen {
  [hidden~=screen] {
    display: inherit;
  }

  [hidden~=screen]:not(:active):not(:focus):not(:target) {
    position: absolute !important;
    clip: rect(0 0 0 0) !important;
  }
}
html {
  font-size: 46.875%;
  color: var(--Black);
}
@media screen and (min-width: 701px) {
  html {
    font-size: 62.5%;
  }
}

body {
  -webkit-text-size-adjust: 100%;
  font-family: "Noto Sans CJK JP", sans-serif;
  font-size: 1.6rem;
  font-weight: 500;
  line-height: 1.2;
}
@media screen and (min-width: 701px) {
  body {
    min-width: 1120px;
  }
}

a, a:link, a:visited, a:active, a:hover {
  color: currentColor;
  text-decoration: none;
}

p {
  line-height: 1.8;
}

sup {
  vertical-align: super;
  font-size: 0.5em;
}

svg {
  transform-box: fill-box;
}

img {
  max-width: 100%;
  height: auto;
}
@media screen and (min-width: 701px) {
  img {
    max-width: initial;
    height: initial;
  }
}

ruby {
  text-indent: 0px;
}
ruby > rt {
  display: block;
  font-size: 50%;
  text-align: start;
}

rt {
  text-indent: 0px;
  line-height: normal;
  text-emphasis: none;
}

_::-webkit-full-page-media, _:future, :root ruby > rt {
  zoom: 0.5;
  font-size: -webkit-ruby-text;
}

.u-disp {
  display: block;
}

.u-none {
  display: none;
}

.u-inline {
  display: inline;
}

.u-ib {
  display: inline-block;
}

.u-flex {
  display: flex;
}

.u-grid {
  display: grid;
}

@media screen and (min-width: 701px) {
  .u-disp-md {
    display: block;
  }

  .u-none-md {
    display: none;
  }

  .u-inline-md {
    display: inline;
  }

  .u-ib-md {
    display: inline-block;
  }

  .u-flex-md {
    display: flex;
  }

  .u-grid-md {
    display: grid;
  }
}
@media screen and (min-width: 1201px) {
  .u-disp-lg {
    display: block;
  }

  .u-none-lg {
    display: none;
  }

  .u-inline-lg {
    display: inline;
  }

  .u-ib-lg {
    display: inline-block;
  }

  .u-flex-lg {
    display: flex;
  }

  .u-grid-lg {
    display: grid;
  }
}
.wt5 {
  width: 5%;
}

@media screen and (min-width: 701px) {
  .wt5-md {
    width: 5%;
  }
}
@media screen and (min-width: 1201px) {
  .wt5-lg {
    width: 5%;
  }
}
.wt10 {
  width: 10%;
}

@media screen and (min-width: 701px) {
  .wt10-md {
    width: 10%;
  }
}
@media screen and (min-width: 1201px) {
  .wt10-lg {
    width: 10%;
  }
}
.wt15 {
  width: 15%;
}

@media screen and (min-width: 701px) {
  .wt15-md {
    width: 15%;
  }
}
@media screen and (min-width: 1201px) {
  .wt15-lg {
    width: 15%;
  }
}
.wt20 {
  width: 20%;
}

@media screen and (min-width: 701px) {
  .wt20-md {
    width: 20%;
  }
}
@media screen and (min-width: 1201px) {
  .wt20-lg {
    width: 20%;
  }
}
.wt25 {
  width: 25%;
}

@media screen and (min-width: 701px) {
  .wt25-md {
    width: 25%;
  }
}
@media screen and (min-width: 1201px) {
  .wt25-lg {
    width: 25%;
  }
}
.wt30 {
  width: 30%;
}

@media screen and (min-width: 701px) {
  .wt30-md {
    width: 30%;
  }
}
@media screen and (min-width: 1201px) {
  .wt30-lg {
    width: 30%;
  }
}
.wt35 {
  width: 35%;
}

@media screen and (min-width: 701px) {
  .wt35-md {
    width: 35%;
  }
}
@media screen and (min-width: 1201px) {
  .wt35-lg {
    width: 35%;
  }
}
.wt40 {
  width: 40%;
}

@media screen and (min-width: 701px) {
  .wt40-md {
    width: 40%;
  }
}
@media screen and (min-width: 1201px) {
  .wt40-lg {
    width: 40%;
  }
}
.wt45 {
  width: 45%;
}

@media screen and (min-width: 701px) {
  .wt45-md {
    width: 45%;
  }
}
@media screen and (min-width: 1201px) {
  .wt45-lg {
    width: 45%;
  }
}
.wt50 {
  width: 50%;
}

@media screen and (min-width: 701px) {
  .wt50-md {
    width: 50%;
  }
}
@media screen and (min-width: 1201px) {
  .wt50-lg {
    width: 50%;
  }
}
.wt55 {
  width: 55%;
}

@media screen and (min-width: 701px) {
  .wt55-md {
    width: 55%;
  }
}
@media screen and (min-width: 1201px) {
  .wt55-lg {
    width: 55%;
  }
}
.wt60 {
  width: 60%;
}

@media screen and (min-width: 701px) {
  .wt60-md {
    width: 60%;
  }
}
@media screen and (min-width: 1201px) {
  .wt60-lg {
    width: 60%;
  }
}
.wt65 {
  width: 65%;
}

@media screen and (min-width: 701px) {
  .wt65-md {
    width: 65%;
  }
}
@media screen and (min-width: 1201px) {
  .wt65-lg {
    width: 65%;
  }
}
.wt70 {
  width: 70%;
}

@media screen and (min-width: 701px) {
  .wt70-md {
    width: 70%;
  }
}
@media screen and (min-width: 1201px) {
  .wt70-lg {
    width: 70%;
  }
}
.wt75 {
  width: 75%;
}

@media screen and (min-width: 701px) {
  .wt75-md {
    width: 75%;
  }
}
@media screen and (min-width: 1201px) {
  .wt75-lg {
    width: 75%;
  }
}
.wt80 {
  width: 80%;
}

@media screen and (min-width: 701px) {
  .wt80-md {
    width: 80%;
  }
}
@media screen and (min-width: 1201px) {
  .wt80-lg {
    width: 80%;
  }
}
.wt85 {
  width: 85%;
}

@media screen and (min-width: 701px) {
  .wt85-md {
    width: 85%;
  }
}
@media screen and (min-width: 1201px) {
  .wt85-lg {
    width: 85%;
  }
}
.wt90 {
  width: 90%;
}

@media screen and (min-width: 701px) {
  .wt90-md {
    width: 90%;
  }
}
@media screen and (min-width: 1201px) {
  .wt90-lg {
    width: 90%;
  }
}
.wt95 {
  width: 95%;
}

@media screen and (min-width: 701px) {
  .wt95-md {
    width: 95%;
  }
}
@media screen and (min-width: 1201px) {
  .wt95-lg {
    width: 95%;
  }
}
.wt100 {
  width: 100%;
}

@media screen and (min-width: 701px) {
  .wt100-md {
    width: 100%;
  }
}
@media screen and (min-width: 1201px) {
  .wt100-lg {
    width: 100%;
  }
}
.mt0 {
  margin-top: 0rem;
}

@media screen and (min-width: 701px) {
  .mt0-md {
    margin-top: 0rem;
  }
}
@media screen and (min-width: 1201px) {
  .mt0-lg {
    margin-top: 0rem;
  }
}
.mt10 {
  margin-top: 1rem;
}

@media screen and (min-width: 701px) {
  .mt10-md {
    margin-top: 1rem;
  }
}
@media screen and (min-width: 1201px) {
  .mt10-lg {
    margin-top: 1rem;
  }
}
.mt20 {
  margin-top: 2rem;
}

@media screen and (min-width: 701px) {
  .mt20-md {
    margin-top: 2rem;
  }
}
@media screen and (min-width: 1201px) {
  .mt20-lg {
    margin-top: 2rem;
  }
}
.mt30 {
  margin-top: 3rem;
}

@media screen and (min-width: 701px) {
  .mt30-md {
    margin-top: 3rem;
  }
}
@media screen and (min-width: 1201px) {
  .mt30-lg {
    margin-top: 3rem;
  }
}
.mt40 {
  margin-top: 4rem;
}

@media screen and (min-width: 701px) {
  .mt40-md {
    margin-top: 4rem;
  }
}
@media screen and (min-width: 1201px) {
  .mt40-lg {
    margin-top: 4rem;
  }
}
.mt50 {
  margin-top: 5rem;
}

@media screen and (min-width: 701px) {
  .mt50-md {
    margin-top: 5rem;
  }
}
@media screen and (min-width: 1201px) {
  .mt50-lg {
    margin-top: 5rem;
  }
}
.mt60 {
  margin-top: 6rem;
}

@media screen and (min-width: 701px) {
  .mt60-md {
    margin-top: 6rem;
  }
}
@media screen and (min-width: 1201px) {
  .mt60-lg {
    margin-top: 6rem;
  }
}
.mt70 {
  margin-top: 7rem;
}

@media screen and (min-width: 701px) {
  .mt70-md {
    margin-top: 7rem;
  }
}
@media screen and (min-width: 1201px) {
  .mt70-lg {
    margin-top: 7rem;
  }
}
.mt80 {
  margin-top: 8rem;
}

@media screen and (min-width: 701px) {
  .mt80-md {
    margin-top: 8rem;
  }
}
@media screen and (min-width: 1201px) {
  .mt80-lg {
    margin-top: 8rem;
  }
}
.mt90 {
  margin-top: 9rem;
}

@media screen and (min-width: 701px) {
  .mt90-md {
    margin-top: 9rem;
  }
}
@media screen and (min-width: 1201px) {
  .mt90-lg {
    margin-top: 9rem;
  }
}
.mt100 {
  margin-top: 10rem;
}

@media screen and (min-width: 701px) {
  .mt100-md {
    margin-top: 10rem;
  }
}
@media screen and (min-width: 1201px) {
  .mt100-lg {
    margin-top: 10rem;
  }
}
.mt110 {
  margin-top: 11rem;
}

@media screen and (min-width: 701px) {
  .mt110-md {
    margin-top: 11rem;
  }
}
@media screen and (min-width: 1201px) {
  .mt110-lg {
    margin-top: 11rem;
  }
}
.mt120 {
  margin-top: 12rem;
}

@media screen and (min-width: 701px) {
  .mt120-md {
    margin-top: 12rem;
  }
}
@media screen and (min-width: 1201px) {
  .mt120-lg {
    margin-top: 12rem;
  }
}
.mt130 {
  margin-top: 13rem;
}

@media screen and (min-width: 701px) {
  .mt130-md {
    margin-top: 13rem;
  }
}
@media screen and (min-width: 1201px) {
  .mt130-lg {
    margin-top: 13rem;
  }
}
.mt140 {
  margin-top: 14rem;
}

@media screen and (min-width: 701px) {
  .mt140-md {
    margin-top: 14rem;
  }
}
@media screen and (min-width: 1201px) {
  .mt140-lg {
    margin-top: 14rem;
  }
}
.mt150 {
  margin-top: 15rem;
}

@media screen and (min-width: 701px) {
  .mt150-md {
    margin-top: 15rem;
  }
}
@media screen and (min-width: 1201px) {
  .mt150-lg {
    margin-top: 15rem;
  }
}
.mt160 {
  margin-top: 16rem;
}

@media screen and (min-width: 701px) {
  .mt160-md {
    margin-top: 16rem;
  }
}
@media screen and (min-width: 1201px) {
  .mt160-lg {
    margin-top: 16rem;
  }
}
.mt170 {
  margin-top: 17rem;
}

@media screen and (min-width: 701px) {
  .mt170-md {
    margin-top: 17rem;
  }
}
@media screen and (min-width: 1201px) {
  .mt170-lg {
    margin-top: 17rem;
  }
}
.mt180 {
  margin-top: 18rem;
}

@media screen and (min-width: 701px) {
  .mt180-md {
    margin-top: 18rem;
  }
}
@media screen and (min-width: 1201px) {
  .mt180-lg {
    margin-top: 18rem;
  }
}
.mt190 {
  margin-top: 19rem;
}

@media screen and (min-width: 701px) {
  .mt190-md {
    margin-top: 19rem;
  }
}
@media screen and (min-width: 1201px) {
  .mt190-lg {
    margin-top: 19rem;
  }
}
.mt200 {
  margin-top: 20rem;
}

@media screen and (min-width: 701px) {
  .mt200-md {
    margin-top: 20rem;
  }
}
@media screen and (min-width: 1201px) {
  .mt200-lg {
    margin-top: 20rem;
  }
}
.mb0 {
  margin-bottom: 0rem;
}

@media screen and (min-width: 701px) {
  .mb0-md {
    margin-bottom: 0rem;
  }
}
@media screen and (min-width: 1201px) {
  .mb0-lg {
    margin-bottom: 0rem;
  }
}
.mb10 {
  margin-bottom: 1rem;
}

@media screen and (min-width: 701px) {
  .mb10-md {
    margin-bottom: 1rem;
  }
}
@media screen and (min-width: 1201px) {
  .mb10-lg {
    margin-bottom: 1rem;
  }
}
.mb20 {
  margin-bottom: 2rem;
}

@media screen and (min-width: 701px) {
  .mb20-md {
    margin-bottom: 2rem;
  }
}
@media screen and (min-width: 1201px) {
  .mb20-lg {
    margin-bottom: 2rem;
  }
}
.mb30 {
  margin-bottom: 3rem;
}

@media screen and (min-width: 701px) {
  .mb30-md {
    margin-bottom: 3rem;
  }
}
@media screen and (min-width: 1201px) {
  .mb30-lg {
    margin-bottom: 3rem;
  }
}
.mb40 {
  margin-bottom: 4rem;
}

@media screen and (min-width: 701px) {
  .mb40-md {
    margin-bottom: 4rem;
  }
}
@media screen and (min-width: 1201px) {
  .mb40-lg {
    margin-bottom: 4rem;
  }
}
.mb50 {
  margin-bottom: 5rem;
}

@media screen and (min-width: 701px) {
  .mb50-md {
    margin-bottom: 5rem;
  }
}
@media screen and (min-width: 1201px) {
  .mb50-lg {
    margin-bottom: 5rem;
  }
}
.mb60 {
  margin-bottom: 6rem;
}

@media screen and (min-width: 701px) {
  .mb60-md {
    margin-bottom: 6rem;
  }
}
@media screen and (min-width: 1201px) {
  .mb60-lg {
    margin-bottom: 6rem;
  }
}
.mb70 {
  margin-bottom: 7rem;
}

@media screen and (min-width: 701px) {
  .mb70-md {
    margin-bottom: 7rem;
  }
}
@media screen and (min-width: 1201px) {
  .mb70-lg {
    margin-bottom: 7rem;
  }
}
.mb80 {
  margin-bottom: 8rem;
}

@media screen and (min-width: 701px) {
  .mb80-md {
    margin-bottom: 8rem;
  }
}
@media screen and (min-width: 1201px) {
  .mb80-lg {
    margin-bottom: 8rem;
  }
}
.mb90 {
  margin-bottom: 9rem;
}

@media screen and (min-width: 701px) {
  .mb90-md {
    margin-bottom: 9rem;
  }
}
@media screen and (min-width: 1201px) {
  .mb90-lg {
    margin-bottom: 9rem;
  }
}
.mb100 {
  margin-bottom: 10rem;
}

@media screen and (min-width: 701px) {
  .mb100-md {
    margin-bottom: 10rem;
  }
}
@media screen and (min-width: 1201px) {
  .mb100-lg {
    margin-bottom: 10rem;
  }
}
.mb110 {
  margin-bottom: 11rem;
}

@media screen and (min-width: 701px) {
  .mb110-md {
    margin-bottom: 11rem;
  }
}
@media screen and (min-width: 1201px) {
  .mb110-lg {
    margin-bottom: 11rem;
  }
}
.mb120 {
  margin-bottom: 12rem;
}

@media screen and (min-width: 701px) {
  .mb120-md {
    margin-bottom: 12rem;
  }
}
@media screen and (min-width: 1201px) {
  .mb120-lg {
    margin-bottom: 12rem;
  }
}
.mb130 {
  margin-bottom: 13rem;
}

@media screen and (min-width: 701px) {
  .mb130-md {
    margin-bottom: 13rem;
  }
}
@media screen and (min-width: 1201px) {
  .mb130-lg {
    margin-bottom: 13rem;
  }
}
.mb140 {
  margin-bottom: 14rem;
}

@media screen and (min-width: 701px) {
  .mb140-md {
    margin-bottom: 14rem;
  }
}
@media screen and (min-width: 1201px) {
  .mb140-lg {
    margin-bottom: 14rem;
  }
}
.mb150 {
  margin-bottom: 15rem;
}

@media screen and (min-width: 701px) {
  .mb150-md {
    margin-bottom: 15rem;
  }
}
@media screen and (min-width: 1201px) {
  .mb150-lg {
    margin-bottom: 15rem;
  }
}
.mb160 {
  margin-bottom: 16rem;
}

@media screen and (min-width: 701px) {
  .mb160-md {
    margin-bottom: 16rem;
  }
}
@media screen and (min-width: 1201px) {
  .mb160-lg {
    margin-bottom: 16rem;
  }
}
.mb170 {
  margin-bottom: 17rem;
}

@media screen and (min-width: 701px) {
  .mb170-md {
    margin-bottom: 17rem;
  }
}
@media screen and (min-width: 1201px) {
  .mb170-lg {
    margin-bottom: 17rem;
  }
}
.mb180 {
  margin-bottom: 18rem;
}

@media screen and (min-width: 701px) {
  .mb180-md {
    margin-bottom: 18rem;
  }
}
@media screen and (min-width: 1201px) {
  .mb180-lg {
    margin-bottom: 18rem;
  }
}
.mb190 {
  margin-bottom: 19rem;
}

@media screen and (min-width: 701px) {
  .mb190-md {
    margin-bottom: 19rem;
  }
}
@media screen and (min-width: 1201px) {
  .mb190-lg {
    margin-bottom: 19rem;
  }
}
.mb200 {
  margin-bottom: 20rem;
}

@media screen and (min-width: 701px) {
  .mb200-md {
    margin-bottom: 20rem;
  }
}
@media screen and (min-width: 1201px) {
  .mb200-lg {
    margin-bottom: 20rem;
  }
}
.pt0 {
  padding-top: 0rem;
}

@media screen and (min-width: 701px) {
  .pt0-md {
    padding-top: 0rem;
  }
}
@media screen and (min-width: 1201px) {
  .pt0-lg {
    padding-top: 0rem;
  }
}
.pt10 {
  padding-top: 1rem;
}

@media screen and (min-width: 701px) {
  .pt10-md {
    padding-top: 1rem;
  }
}
@media screen and (min-width: 1201px) {
  .pt10-lg {
    padding-top: 1rem;
  }
}
.pt20 {
  padding-top: 2rem;
}

@media screen and (min-width: 701px) {
  .pt20-md {
    padding-top: 2rem;
  }
}
@media screen and (min-width: 1201px) {
  .pt20-lg {
    padding-top: 2rem;
  }
}
.pt30 {
  padding-top: 3rem;
}

@media screen and (min-width: 701px) {
  .pt30-md {
    padding-top: 3rem;
  }
}
@media screen and (min-width: 1201px) {
  .pt30-lg {
    padding-top: 3rem;
  }
}
.pt40 {
  padding-top: 4rem;
}

@media screen and (min-width: 701px) {
  .pt40-md {
    padding-top: 4rem;
  }
}
@media screen and (min-width: 1201px) {
  .pt40-lg {
    padding-top: 4rem;
  }
}
.pt50 {
  padding-top: 5rem;
}

@media screen and (min-width: 701px) {
  .pt50-md {
    padding-top: 5rem;
  }
}
@media screen and (min-width: 1201px) {
  .pt50-lg {
    padding-top: 5rem;
  }
}
.pt60 {
  padding-top: 6rem;
}

@media screen and (min-width: 701px) {
  .pt60-md {
    padding-top: 6rem;
  }
}
@media screen and (min-width: 1201px) {
  .pt60-lg {
    padding-top: 6rem;
  }
}
.pt70 {
  padding-top: 7rem;
}

@media screen and (min-width: 701px) {
  .pt70-md {
    padding-top: 7rem;
  }
}
@media screen and (min-width: 1201px) {
  .pt70-lg {
    padding-top: 7rem;
  }
}
.pt80 {
  padding-top: 8rem;
}

@media screen and (min-width: 701px) {
  .pt80-md {
    padding-top: 8rem;
  }
}
@media screen and (min-width: 1201px) {
  .pt80-lg {
    padding-top: 8rem;
  }
}
.pt90 {
  padding-top: 9rem;
}

@media screen and (min-width: 701px) {
  .pt90-md {
    padding-top: 9rem;
  }
}
@media screen and (min-width: 1201px) {
  .pt90-lg {
    padding-top: 9rem;
  }
}
.pt100 {
  padding-top: 10rem;
}

@media screen and (min-width: 701px) {
  .pt100-md {
    padding-top: 10rem;
  }
}
@media screen and (min-width: 1201px) {
  .pt100-lg {
    padding-top: 10rem;
  }
}
.pt110 {
  padding-top: 11rem;
}

@media screen and (min-width: 701px) {
  .pt110-md {
    padding-top: 11rem;
  }
}
@media screen and (min-width: 1201px) {
  .pt110-lg {
    padding-top: 11rem;
  }
}
.pt120 {
  padding-top: 12rem;
}

@media screen and (min-width: 701px) {
  .pt120-md {
    padding-top: 12rem;
  }
}
@media screen and (min-width: 1201px) {
  .pt120-lg {
    padding-top: 12rem;
  }
}
.pt130 {
  padding-top: 13rem;
}

@media screen and (min-width: 701px) {
  .pt130-md {
    padding-top: 13rem;
  }
}
@media screen and (min-width: 1201px) {
  .pt130-lg {
    padding-top: 13rem;
  }
}
.pt140 {
  padding-top: 14rem;
}

@media screen and (min-width: 701px) {
  .pt140-md {
    padding-top: 14rem;
  }
}
@media screen and (min-width: 1201px) {
  .pt140-lg {
    padding-top: 14rem;
  }
}
.pt150 {
  padding-top: 15rem;
}

@media screen and (min-width: 701px) {
  .pt150-md {
    padding-top: 15rem;
  }
}
@media screen and (min-width: 1201px) {
  .pt150-lg {
    padding-top: 15rem;
  }
}
.pt160 {
  padding-top: 16rem;
}

@media screen and (min-width: 701px) {
  .pt160-md {
    padding-top: 16rem;
  }
}
@media screen and (min-width: 1201px) {
  .pt160-lg {
    padding-top: 16rem;
  }
}
.pt170 {
  padding-top: 17rem;
}

@media screen and (min-width: 701px) {
  .pt170-md {
    padding-top: 17rem;
  }
}
@media screen and (min-width: 1201px) {
  .pt170-lg {
    padding-top: 17rem;
  }
}
.pt180 {
  padding-top: 18rem;
}

@media screen and (min-width: 701px) {
  .pt180-md {
    padding-top: 18rem;
  }
}
@media screen and (min-width: 1201px) {
  .pt180-lg {
    padding-top: 18rem;
  }
}
.pt190 {
  padding-top: 19rem;
}

@media screen and (min-width: 701px) {
  .pt190-md {
    padding-top: 19rem;
  }
}
@media screen and (min-width: 1201px) {
  .pt190-lg {
    padding-top: 19rem;
  }
}
.pt200 {
  padding-top: 20rem;
}

@media screen and (min-width: 701px) {
  .pt200-md {
    padding-top: 20rem;
  }
}
@media screen and (min-width: 1201px) {
  .pt200-lg {
    padding-top: 20rem;
  }
}
.pb0 {
  padding-bottom: 0rem;
}

@media screen and (min-width: 701px) {
  .pb0-md {
    padding-bottom: 0rem;
  }
}
@media screen and (min-width: 1201px) {
  .pb0-lg {
    padding-bottom: 0rem;
  }
}
.pb10 {
  padding-bottom: 1rem;
}

@media screen and (min-width: 701px) {
  .pb10-md {
    padding-bottom: 1rem;
  }
}
@media screen and (min-width: 1201px) {
  .pb10-lg {
    padding-bottom: 1rem;
  }
}
.pb20 {
  padding-bottom: 2rem;
}

@media screen and (min-width: 701px) {
  .pb20-md {
    padding-bottom: 2rem;
  }
}
@media screen and (min-width: 1201px) {
  .pb20-lg {
    padding-bottom: 2rem;
  }
}
.pb30 {
  padding-bottom: 3rem;
}

@media screen and (min-width: 701px) {
  .pb30-md {
    padding-bottom: 3rem;
  }
}
@media screen and (min-width: 1201px) {
  .pb30-lg {
    padding-bottom: 3rem;
  }
}
.pb40 {
  padding-bottom: 4rem;
}

@media screen and (min-width: 701px) {
  .pb40-md {
    padding-bottom: 4rem;
  }
}
@media screen and (min-width: 1201px) {
  .pb40-lg {
    padding-bottom: 4rem;
  }
}
.pb50 {
  padding-bottom: 5rem;
}

@media screen and (min-width: 701px) {
  .pb50-md {
    padding-bottom: 5rem;
  }
}
@media screen and (min-width: 1201px) {
  .pb50-lg {
    padding-bottom: 5rem;
  }
}
.pb60 {
  padding-bottom: 6rem;
}

@media screen and (min-width: 701px) {
  .pb60-md {
    padding-bottom: 6rem;
  }
}
@media screen and (min-width: 1201px) {
  .pb60-lg {
    padding-bottom: 6rem;
  }
}
.pb70 {
  padding-bottom: 7rem;
}

@media screen and (min-width: 701px) {
  .pb70-md {
    padding-bottom: 7rem;
  }
}
@media screen and (min-width: 1201px) {
  .pb70-lg {
    padding-bottom: 7rem;
  }
}
.pb80 {
  padding-bottom: 8rem;
}

@media screen and (min-width: 701px) {
  .pb80-md {
    padding-bottom: 8rem;
  }
}
@media screen and (min-width: 1201px) {
  .pb80-lg {
    padding-bottom: 8rem;
  }
}
.pb90 {
  padding-bottom: 9rem;
}

@media screen and (min-width: 701px) {
  .pb90-md {
    padding-bottom: 9rem;
  }
}
@media screen and (min-width: 1201px) {
  .pb90-lg {
    padding-bottom: 9rem;
  }
}
.pb100 {
  padding-bottom: 10rem;
}

@media screen and (min-width: 701px) {
  .pb100-md {
    padding-bottom: 10rem;
  }
}
@media screen and (min-width: 1201px) {
  .pb100-lg {
    padding-bottom: 10rem;
  }
}
.pb110 {
  padding-bottom: 11rem;
}

@media screen and (min-width: 701px) {
  .pb110-md {
    padding-bottom: 11rem;
  }
}
@media screen and (min-width: 1201px) {
  .pb110-lg {
    padding-bottom: 11rem;
  }
}
.pb120 {
  padding-bottom: 12rem;
}

@media screen and (min-width: 701px) {
  .pb120-md {
    padding-bottom: 12rem;
  }
}
@media screen and (min-width: 1201px) {
  .pb120-lg {
    padding-bottom: 12rem;
  }
}
.pb130 {
  padding-bottom: 13rem;
}

@media screen and (min-width: 701px) {
  .pb130-md {
    padding-bottom: 13rem;
  }
}
@media screen and (min-width: 1201px) {
  .pb130-lg {
    padding-bottom: 13rem;
  }
}
.pb140 {
  padding-bottom: 14rem;
}

@media screen and (min-width: 701px) {
  .pb140-md {
    padding-bottom: 14rem;
  }
}
@media screen and (min-width: 1201px) {
  .pb140-lg {
    padding-bottom: 14rem;
  }
}
.pb150 {
  padding-bottom: 15rem;
}

@media screen and (min-width: 701px) {
  .pb150-md {
    padding-bottom: 15rem;
  }
}
@media screen and (min-width: 1201px) {
  .pb150-lg {
    padding-bottom: 15rem;
  }
}
.pb160 {
  padding-bottom: 16rem;
}

@media screen and (min-width: 701px) {
  .pb160-md {
    padding-bottom: 16rem;
  }
}
@media screen and (min-width: 1201px) {
  .pb160-lg {
    padding-bottom: 16rem;
  }
}
.pb170 {
  padding-bottom: 17rem;
}

@media screen and (min-width: 701px) {
  .pb170-md {
    padding-bottom: 17rem;
  }
}
@media screen and (min-width: 1201px) {
  .pb170-lg {
    padding-bottom: 17rem;
  }
}
.pb180 {
  padding-bottom: 18rem;
}

@media screen and (min-width: 701px) {
  .pb180-md {
    padding-bottom: 18rem;
  }
}
@media screen and (min-width: 1201px) {
  .pb180-lg {
    padding-bottom: 18rem;
  }
}
.pb190 {
  padding-bottom: 19rem;
}

@media screen and (min-width: 701px) {
  .pb190-md {
    padding-bottom: 19rem;
  }
}
@media screen and (min-width: 1201px) {
  .pb190-lg {
    padding-bottom: 19rem;
  }
}
.pb200 {
  padding-bottom: 20rem;
}

@media screen and (min-width: 701px) {
  .pb200-md {
    padding-bottom: 20rem;
  }
}
@media screen and (min-width: 1201px) {
  .pb200-lg {
    padding-bottom: 20rem;
  }
}
.mAuto {
  margin-left: auto;
  margin-right: auto;
}

@media screen and (min-width: 701px) {
  .mAuto-md {
    margin-left: auto;
    margin-right: auto;
  }
}
@media screen and (min-width: 1201px) {
  .mAuto-lg {
    margin-left: auto;
    margin-right: auto;
  }
}
.posRel {
  position: relative;
}
.posAbs {
  position: absolute;
}
.posFixed {
  position: fixed;
}
.posSticky {
  position: sticky;
}

@media screen and (min-width: 701px) {
  .posRel-md {
    position: relative;
  }
  .posAbs-md {
    position: absolute;
  }
  .posFixed-md {
    position: fixed;
  }
  .posSticky-md {
    position: sticky;
  }
}
@media screen and (min-width: 1201px) {
  .posRel-lg {
    position: relative;
  }
  .posAbs-lg {
    position: absolute;
  }
  .posFixed-lg {
    position: fixed;
  }
  .posSticky-lg {
    position: sticky;
  }
}
.u-flex-contentCenter {
  justify-content: center;
}
.u-flex-contentStart {
  justify-content: flex-start;
}
.u-flex-contentEnd {
  justify-content: flex-end;
}
.u-flex-contentSpace {
  justify-content: space-around;
}
.u-flex-contentBetween {
  justify-content: space-between;
}
.u-flex-alignCenter {
  align-items: center;
}
.u-flex-alignStart {
  align-items: flex-start;
}
.u-flex-alignEnd {
  align-items: flex-end;
}
.u-flex-alignSpace {
  align-items: space-around;
}
.u-flex-alignBetween {
  align-items: space-between;
}
.u-flex-directCol {
  flex-direction: column;
}
.u-flex-directColRevs {
  flex-direction: column-reverse;
}
.u-flex-directRow {
  flex-direction: row;
}
.u-flex-directRowRevs {
  flex-direction: row-reverse;
}

.fs10 {
  font-size: 1rem;
}

@media screen and (min-width: 701px) {
  .fs10-md {
    font-size: 1rem;
  }
}
@media screen and (min-width: 1201px) {
  .fs10-lg {
    font-size: 1rem;
  }
}
.fs11 {
  font-size: 1.1rem;
}

@media screen and (min-width: 701px) {
  .fs11-md {
    font-size: 1.1rem;
  }
}
@media screen and (min-width: 1201px) {
  .fs11-lg {
    font-size: 1.1rem;
  }
}
.fs12 {
  font-size: 1.2rem;
}

@media screen and (min-width: 701px) {
  .fs12-md {
    font-size: 1.2rem;
  }
}
@media screen and (min-width: 1201px) {
  .fs12-lg {
    font-size: 1.2rem;
  }
}
.fs13 {
  font-size: 1.3rem;
}

@media screen and (min-width: 701px) {
  .fs13-md {
    font-size: 1.3rem;
  }
}
@media screen and (min-width: 1201px) {
  .fs13-lg {
    font-size: 1.3rem;
  }
}
.fs14 {
  font-size: 1.4rem;
}

@media screen and (min-width: 701px) {
  .fs14-md {
    font-size: 1.4rem;
  }
}
@media screen and (min-width: 1201px) {
  .fs14-lg {
    font-size: 1.4rem;
  }
}
.fs15 {
  font-size: 1.5rem;
}

@media screen and (min-width: 701px) {
  .fs15-md {
    font-size: 1.5rem;
  }
}
@media screen and (min-width: 1201px) {
  .fs15-lg {
    font-size: 1.5rem;
  }
}
.fs16 {
  font-size: 1.6rem;
}

@media screen and (min-width: 701px) {
  .fs16-md {
    font-size: 1.6rem;
  }
}
@media screen and (min-width: 1201px) {
  .fs16-lg {
    font-size: 1.6rem;
  }
}
.fs17 {
  font-size: 1.7rem;
}

@media screen and (min-width: 701px) {
  .fs17-md {
    font-size: 1.7rem;
  }
}
@media screen and (min-width: 1201px) {
  .fs17-lg {
    font-size: 1.7rem;
  }
}
.fs18 {
  font-size: 1.8rem;
}

@media screen and (min-width: 701px) {
  .fs18-md {
    font-size: 1.8rem;
  }
}
@media screen and (min-width: 1201px) {
  .fs18-lg {
    font-size: 1.8rem;
  }
}
.fs19 {
  font-size: 1.9rem;
}

@media screen and (min-width: 701px) {
  .fs19-md {
    font-size: 1.9rem;
  }
}
@media screen and (min-width: 1201px) {
  .fs19-lg {
    font-size: 1.9rem;
  }
}
.fs20 {
  font-size: 2rem;
}

@media screen and (min-width: 701px) {
  .fs20-md {
    font-size: 2rem;
  }
}
@media screen and (min-width: 1201px) {
  .fs20-lg {
    font-size: 2rem;
  }
}
.fs21 {
  font-size: 2.1rem;
}

@media screen and (min-width: 701px) {
  .fs21-md {
    font-size: 2.1rem;
  }
}
@media screen and (min-width: 1201px) {
  .fs21-lg {
    font-size: 2.1rem;
  }
}
.fs22 {
  font-size: 2.2rem;
}

@media screen and (min-width: 701px) {
  .fs22-md {
    font-size: 2.2rem;
  }
}
@media screen and (min-width: 1201px) {
  .fs22-lg {
    font-size: 2.2rem;
  }
}
.fs23 {
  font-size: 2.3rem;
}

@media screen and (min-width: 701px) {
  .fs23-md {
    font-size: 2.3rem;
  }
}
@media screen and (min-width: 1201px) {
  .fs23-lg {
    font-size: 2.3rem;
  }
}
.fs24 {
  font-size: 2.4rem;
}

@media screen and (min-width: 701px) {
  .fs24-md {
    font-size: 2.4rem;
  }
}
@media screen and (min-width: 1201px) {
  .fs24-lg {
    font-size: 2.4rem;
  }
}
.fs25 {
  font-size: 2.5rem;
}

@media screen and (min-width: 701px) {
  .fs25-md {
    font-size: 2.5rem;
  }
}
@media screen and (min-width: 1201px) {
  .fs25-lg {
    font-size: 2.5rem;
  }
}
.fs26 {
  font-size: 2.6rem;
}

@media screen and (min-width: 701px) {
  .fs26-md {
    font-size: 2.6rem;
  }
}
@media screen and (min-width: 1201px) {
  .fs26-lg {
    font-size: 2.6rem;
  }
}
.fs27 {
  font-size: 2.7rem;
}

@media screen and (min-width: 701px) {
  .fs27-md {
    font-size: 2.7rem;
  }
}
@media screen and (min-width: 1201px) {
  .fs27-lg {
    font-size: 2.7rem;
  }
}
.fs28 {
  font-size: 2.8rem;
}

@media screen and (min-width: 701px) {
  .fs28-md {
    font-size: 2.8rem;
  }
}
@media screen and (min-width: 1201px) {
  .fs28-lg {
    font-size: 2.8rem;
  }
}
.fs29 {
  font-size: 2.9rem;
}

@media screen and (min-width: 701px) {
  .fs29-md {
    font-size: 2.9rem;
  }
}
@media screen and (min-width: 1201px) {
  .fs29-lg {
    font-size: 2.9rem;
  }
}
.fs30 {
  font-size: 3rem;
}

@media screen and (min-width: 701px) {
  .fs30-md {
    font-size: 3rem;
  }
}
@media screen and (min-width: 1201px) {
  .fs30-lg {
    font-size: 3rem;
  }
}
.fs31 {
  font-size: 3.1rem;
}

@media screen and (min-width: 701px) {
  .fs31-md {
    font-size: 3.1rem;
  }
}
@media screen and (min-width: 1201px) {
  .fs31-lg {
    font-size: 3.1rem;
  }
}
.fs32 {
  font-size: 3.2rem;
}

@media screen and (min-width: 701px) {
  .fs32-md {
    font-size: 3.2rem;
  }
}
@media screen and (min-width: 1201px) {
  .fs32-lg {
    font-size: 3.2rem;
  }
}
.fs33 {
  font-size: 3.3rem;
}

@media screen and (min-width: 701px) {
  .fs33-md {
    font-size: 3.3rem;
  }
}
@media screen and (min-width: 1201px) {
  .fs33-lg {
    font-size: 3.3rem;
  }
}
.fs34 {
  font-size: 3.4rem;
}

@media screen and (min-width: 701px) {
  .fs34-md {
    font-size: 3.4rem;
  }
}
@media screen and (min-width: 1201px) {
  .fs34-lg {
    font-size: 3.4rem;
  }
}
.fs35 {
  font-size: 3.5rem;
}

@media screen and (min-width: 701px) {
  .fs35-md {
    font-size: 3.5rem;
  }
}
@media screen and (min-width: 1201px) {
  .fs35-lg {
    font-size: 3.5rem;
  }
}
.fs36 {
  font-size: 3.6rem;
}

@media screen and (min-width: 701px) {
  .fs36-md {
    font-size: 3.6rem;
  }
}
@media screen and (min-width: 1201px) {
  .fs36-lg {
    font-size: 3.6rem;
  }
}
.fs37 {
  font-size: 3.7rem;
}

@media screen and (min-width: 701px) {
  .fs37-md {
    font-size: 3.7rem;
  }
}
@media screen and (min-width: 1201px) {
  .fs37-lg {
    font-size: 3.7rem;
  }
}
.fs38 {
  font-size: 3.8rem;
}

@media screen and (min-width: 701px) {
  .fs38-md {
    font-size: 3.8rem;
  }
}
@media screen and (min-width: 1201px) {
  .fs38-lg {
    font-size: 3.8rem;
  }
}
.fs39 {
  font-size: 3.9rem;
}

@media screen and (min-width: 701px) {
  .fs39-md {
    font-size: 3.9rem;
  }
}
@media screen and (min-width: 1201px) {
  .fs39-lg {
    font-size: 3.9rem;
  }
}
.fs40 {
  font-size: 4rem;
}

@media screen and (min-width: 701px) {
  .fs40-md {
    font-size: 4rem;
  }
}
@media screen and (min-width: 1201px) {
  .fs40-lg {
    font-size: 4rem;
  }
}
.fs41 {
  font-size: 4.1rem;
}

@media screen and (min-width: 701px) {
  .fs41-md {
    font-size: 4.1rem;
  }
}
@media screen and (min-width: 1201px) {
  .fs41-lg {
    font-size: 4.1rem;
  }
}
.fs42 {
  font-size: 4.2rem;
}

@media screen and (min-width: 701px) {
  .fs42-md {
    font-size: 4.2rem;
  }
}
@media screen and (min-width: 1201px) {
  .fs42-lg {
    font-size: 4.2rem;
  }
}
.fs43 {
  font-size: 4.3rem;
}

@media screen and (min-width: 701px) {
  .fs43-md {
    font-size: 4.3rem;
  }
}
@media screen and (min-width: 1201px) {
  .fs43-lg {
    font-size: 4.3rem;
  }
}
.fs44 {
  font-size: 4.4rem;
}

@media screen and (min-width: 701px) {
  .fs44-md {
    font-size: 4.4rem;
  }
}
@media screen and (min-width: 1201px) {
  .fs44-lg {
    font-size: 4.4rem;
  }
}
.fs45 {
  font-size: 4.5rem;
}

@media screen and (min-width: 701px) {
  .fs45-md {
    font-size: 4.5rem;
  }
}
@media screen and (min-width: 1201px) {
  .fs45-lg {
    font-size: 4.5rem;
  }
}
.fs46 {
  font-size: 4.6rem;
}

@media screen and (min-width: 701px) {
  .fs46-md {
    font-size: 4.6rem;
  }
}
@media screen and (min-width: 1201px) {
  .fs46-lg {
    font-size: 4.6rem;
  }
}
.fs47 {
  font-size: 4.7rem;
}

@media screen and (min-width: 701px) {
  .fs47-md {
    font-size: 4.7rem;
  }
}
@media screen and (min-width: 1201px) {
  .fs47-lg {
    font-size: 4.7rem;
  }
}
.fs48 {
  font-size: 4.8rem;
}

@media screen and (min-width: 701px) {
  .fs48-md {
    font-size: 4.8rem;
  }
}
@media screen and (min-width: 1201px) {
  .fs48-lg {
    font-size: 4.8rem;
  }
}
.fs49 {
  font-size: 4.9rem;
}

@media screen and (min-width: 701px) {
  .fs49-md {
    font-size: 4.9rem;
  }
}
@media screen and (min-width: 1201px) {
  .fs49-lg {
    font-size: 4.9rem;
  }
}
.fs50 {
  font-size: 5rem;
}

@media screen and (min-width: 701px) {
  .fs50-md {
    font-size: 5rem;
  }
}
@media screen and (min-width: 1201px) {
  .fs50-lg {
    font-size: 5rem;
  }
}
.fw1 {
  font-weight: 100;
}

@media screen and (min-width: 701px) {
  .fw1-md {
    font-weight: 100;
  }
}
@media screen and (min-width: 1201px) {
  .fw1-lg {
    font-weight: 100;
  }
}
.fw2 {
  font-weight: 200;
}

@media screen and (min-width: 701px) {
  .fw2-md {
    font-weight: 200;
  }
}
@media screen and (min-width: 1201px) {
  .fw2-lg {
    font-weight: 200;
  }
}
.fw3 {
  font-weight: 300;
}

@media screen and (min-width: 701px) {
  .fw3-md {
    font-weight: 300;
  }
}
@media screen and (min-width: 1201px) {
  .fw3-lg {
    font-weight: 300;
  }
}
.fw4 {
  font-weight: 400;
}

@media screen and (min-width: 701px) {
  .fw4-md {
    font-weight: 400;
  }
}
@media screen and (min-width: 1201px) {
  .fw4-lg {
    font-weight: 400;
  }
}
.fw5 {
  font-weight: 500;
}

@media screen and (min-width: 701px) {
  .fw5-md {
    font-weight: 500;
  }
}
@media screen and (min-width: 1201px) {
  .fw5-lg {
    font-weight: 500;
  }
}
.fw6 {
  font-weight: 600;
}

@media screen and (min-width: 701px) {
  .fw6-md {
    font-weight: 600;
  }
}
@media screen and (min-width: 1201px) {
  .fw6-lg {
    font-weight: 600;
  }
}
.fw7 {
  font-weight: 700;
}

@media screen and (min-width: 701px) {
  .fw7-md {
    font-weight: 700;
  }
}
@media screen and (min-width: 1201px) {
  .fw7-lg {
    font-weight: 700;
  }
}
.fw8 {
  font-weight: 800;
}

@media screen and (min-width: 701px) {
  .fw8-md {
    font-weight: 800;
  }
}
@media screen and (min-width: 1201px) {
  .fw8-lg {
    font-weight: 800;
  }
}
.fw9 {
  font-weight: 900;
}

@media screen and (min-width: 701px) {
  .fw9-md {
    font-weight: 900;
  }
}
@media screen and (min-width: 1201px) {
  .fw9-lg {
    font-weight: 900;
  }
}
.lh0 {
  line-height: 0;
}

@media screen and (min-width: 701px) {
  .lh0-md {
    line-height: 0;
  }
}
@media screen and (min-width: 1201px) {
  .lh0-lg {
    line-height: 0;
  }
}
.lh1 {
  line-height: 0.1;
}

@media screen and (min-width: 701px) {
  .lh1-md {
    line-height: 0.1;
  }
}
@media screen and (min-width: 1201px) {
  .lh1-lg {
    line-height: 0.1;
  }
}
.lh2 {
  line-height: 0.2;
}

@media screen and (min-width: 701px) {
  .lh2-md {
    line-height: 0.2;
  }
}
@media screen and (min-width: 1201px) {
  .lh2-lg {
    line-height: 0.2;
  }
}
.lh3 {
  line-height: 0.3;
}

@media screen and (min-width: 701px) {
  .lh3-md {
    line-height: 0.3;
  }
}
@media screen and (min-width: 1201px) {
  .lh3-lg {
    line-height: 0.3;
  }
}
.lh4 {
  line-height: 0.4;
}

@media screen and (min-width: 701px) {
  .lh4-md {
    line-height: 0.4;
  }
}
@media screen and (min-width: 1201px) {
  .lh4-lg {
    line-height: 0.4;
  }
}
.lh5 {
  line-height: 0.5;
}

@media screen and (min-width: 701px) {
  .lh5-md {
    line-height: 0.5;
  }
}
@media screen and (min-width: 1201px) {
  .lh5-lg {
    line-height: 0.5;
  }
}
.lh6 {
  line-height: 0.6;
}

@media screen and (min-width: 701px) {
  .lh6-md {
    line-height: 0.6;
  }
}
@media screen and (min-width: 1201px) {
  .lh6-lg {
    line-height: 0.6;
  }
}
.lh7 {
  line-height: 0.7;
}

@media screen and (min-width: 701px) {
  .lh7-md {
    line-height: 0.7;
  }
}
@media screen and (min-width: 1201px) {
  .lh7-lg {
    line-height: 0.7;
  }
}
.lh8 {
  line-height: 0.8;
}

@media screen and (min-width: 701px) {
  .lh8-md {
    line-height: 0.8;
  }
}
@media screen and (min-width: 1201px) {
  .lh8-lg {
    line-height: 0.8;
  }
}
.lh9 {
  line-height: 0.9;
}

@media screen and (min-width: 701px) {
  .lh9-md {
    line-height: 0.9;
  }
}
@media screen and (min-width: 1201px) {
  .lh9-lg {
    line-height: 0.9;
  }
}
.lh10 {
  line-height: 1;
}

@media screen and (min-width: 701px) {
  .lh10-md {
    line-height: 1;
  }
}
@media screen and (min-width: 1201px) {
  .lh10-lg {
    line-height: 1;
  }
}
.lh11 {
  line-height: 1.1;
}

@media screen and (min-width: 701px) {
  .lh11-md {
    line-height: 1.1;
  }
}
@media screen and (min-width: 1201px) {
  .lh11-lg {
    line-height: 1.1;
  }
}
.lh12 {
  line-height: 1.2;
}

@media screen and (min-width: 701px) {
  .lh12-md {
    line-height: 1.2;
  }
}
@media screen and (min-width: 1201px) {
  .lh12-lg {
    line-height: 1.2;
  }
}
.lh13 {
  line-height: 1.3;
}

@media screen and (min-width: 701px) {
  .lh13-md {
    line-height: 1.3;
  }
}
@media screen and (min-width: 1201px) {
  .lh13-lg {
    line-height: 1.3;
  }
}
.lh14 {
  line-height: 1.4;
}

@media screen and (min-width: 701px) {
  .lh14-md {
    line-height: 1.4;
  }
}
@media screen and (min-width: 1201px) {
  .lh14-lg {
    line-height: 1.4;
  }
}
.lh15 {
  line-height: 1.5;
}

@media screen and (min-width: 701px) {
  .lh15-md {
    line-height: 1.5;
  }
}
@media screen and (min-width: 1201px) {
  .lh15-lg {
    line-height: 1.5;
  }
}
.lh16 {
  line-height: 1.6;
}

@media screen and (min-width: 701px) {
  .lh16-md {
    line-height: 1.6;
  }
}
@media screen and (min-width: 1201px) {
  .lh16-lg {
    line-height: 1.6;
  }
}
.lh17 {
  line-height: 1.7;
}

@media screen and (min-width: 701px) {
  .lh17-md {
    line-height: 1.7;
  }
}
@media screen and (min-width: 1201px) {
  .lh17-lg {
    line-height: 1.7;
  }
}
.lh18 {
  line-height: 1.8;
}

@media screen and (min-width: 701px) {
  .lh18-md {
    line-height: 1.8;
  }
}
@media screen and (min-width: 1201px) {
  .lh18-lg {
    line-height: 1.8;
  }
}
.lh19 {
  line-height: 1.9;
}

@media screen and (min-width: 701px) {
  .lh19-md {
    line-height: 1.9;
  }
}
@media screen and (min-width: 1201px) {
  .lh19-lg {
    line-height: 1.9;
  }
}
.lh20 {
  line-height: 2;
}

@media screen and (min-width: 701px) {
  .lh20-md {
    line-height: 2;
  }
}
@media screen and (min-width: 1201px) {
  .lh20-lg {
    line-height: 2;
  }
}
.ls-10 {
  letter-spacing: -1;
}

.ls-9 {
  letter-spacing: -0.9;
}

.ls-8 {
  letter-spacing: -0.8;
}

.ls-7 {
  letter-spacing: -0.7;
}

.ls-6 {
  letter-spacing: -0.6;
}

.ls-5 {
  letter-spacing: -0.5;
}

.ls-4 {
  letter-spacing: -0.4;
}

.ls-3 {
  letter-spacing: -0.3;
}

.ls-2 {
  letter-spacing: -0.2;
}

.ls-1 {
  letter-spacing: -0.1;
}

.ls0 {
  letter-spacing: 0;
}

.ls1 {
  letter-spacing: 0.1;
}

.ls2 {
  letter-spacing: 0.2;
}

.ls3 {
  letter-spacing: 0.3;
}

.ls4 {
  letter-spacing: 0.4;
}

.ls5 {
  letter-spacing: 0.5;
}

.ls6 {
  letter-spacing: 0.6;
}

.ls7 {
  letter-spacing: 0.7;
}

.ls8 {
  letter-spacing: 0.8;
}

.ls9 {
  letter-spacing: 0.9;
}

.ls10 {
  letter-spacing: 1;
}

.u-wbr {
  word-break: keep-all;
  overflow-wrap: break-word;
}

.alignCenter {
  text-align: center;
}
.alignLeft {
  text-align: left;
}
.alignRight {
  text-align: right;
}
.alignJustify {
  text-align: justify;
}

@media screen and (min-width: 701px) {
  .alignCenter-md {
    text-align: center;
  }
  .alignLeft-md {
    text-align: left;
  }
  .alignRight-md {
    text-align: right;
  }
  .alignJustify-md {
    text-align: justify;
  }
}
@media screen and (min-width: 1201px) {
  .alignCenter-lg {
    text-align: center;
  }
  .alignLeft-lg {
    text-align: left;
  }
  .alignRight-lg {
    text-align: right;
  }
  .alignJustify-lg {
    text-align: justify;
  }
}
.bgMain {
  background-color: var(--Main);
}

.txtMain {
  color: var(--Main);
}

.bdrMain {
  border-color: var(--Main);
}

.bgWhite {
  background-color: var(--White);
}

.txtWhite {
  color: var(--White);
}

.bdrWhite {
  border-color: var(--White);
}

.bgBlack {
  background-color: var(--Black);
}

.txtBlack {
  color: var(--Black);
}

.bdrBlack {
  border-color: var(--Black);
}

.u-shake {
  animation: shake 2s infinite;
}

@keyframes shake {
  50% {
    transform: scale(1, 1);
  }
  52% {
    transform: scale(0.98, 0.95);
  }
  54% {
    transform: scale(1, 1);
  }
  56% {
    transform: scale(0.98, 0.95);
  }
  58% {
    transform: scale(1, 1);
  }
  60% {
    transform: scale(0.98, 0.95);
  }
}